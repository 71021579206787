import * as types from './actionTypes';

export function setField(field, payload) {
  return {
    type: types.SET_FIELD,
    field,
    payload
  }
}

export function resetImagePosition() {
  return {
    type: types.RESET_IMAGE_POSITION,
  }
}

export function setImagePosition(position) {
  return {
    type: types.SET_IMAGE_POSITION,
    position
  }
}
