import style from './style.module.css';

const TextInput = ({ handleChange, value, identifier, limit = 0, type = 'text' }) => {

  const handleKeyUp = (event) => {
    if (limit > 0 && limit < event.currentTarget.value.length) {
      event.currentTarget.value = event.currentTarget.value.substring(0, limit);
      return;
    }

    handleChange(identifier, event.currentTarget.value)
  };

  return (
    <div className={style.textInputGroup}>
      {limit > 0 && (
        <div className={style.characterCount}>
          <span>
            {value.length}
          </span>
          <span>{limit}</span>
        </div>
      )}
      <input
        id={identifier}
        className={`${style.textInput} ${limit > 0 ? style.textInputLimited : ''}`}
        type={type}
        onChange={handleKeyUp}
        value={value}
      />
    </div>
  );
}

export default TextInput;
