import React from 'react'
import { useSelector } from 'react-redux'

import LinkedInBanner from '../../templates/LinkedInBanner'
import LinkedInPost from '../../templates/LinkedInPost'
import LinkedInProfilePicture from '../../templates/LinkedInProfilePicture'

import { optionsSize } from '../form/fieldOptions'

import Fancybox from '../../components/fancybox'
import FancyboxButton from '../../components/fancyboxButton'
import nl from '@fancyapps/ui/src/Fancybox/l10n/nl'

import './size.css'
import globalStyle from '../../style.module.css'
import style from './style.module.css'

const Preview = ({ info = false, preview = false, editor = false }) => {
  const { media_size, media_type } = useSelector(state => state.form)

  const selectedType = optionsSize.filter(r => r.key === media_type)[0]
  const selectedSize = selectedType?.options.find(option => option.value === media_size)

  return (
    <React.Fragment>
      <div className={`${style.preview} preview-container`}>
        {
          {
            'linkedin-post': <LinkedInPost preview={preview} editor={editor} />,
            'linkedin-banner': <LinkedInBanner preview={preview} editor={editor} />,
            'linkedin-profile-picture': <LinkedInProfilePicture preview={preview} editor={editor} />,
          }[media_size]
        }
      </div>
      {info && media_size === 'linkedin-banner' && (
        <div className={style.previewNav}>
          <div className={style.previewType}>{selectedSize.label ?? ''}</div>
          <Fancybox options={{ infinite: false, l10n: nl, template: { closeButton: '<span class="close"></span> Sluiten' }, dragToClose: false }}>
            <FancyboxButton type="dialog" src="#dialog-content">
              <span className={`${globalStyle.icon} ${globalStyle.iconZoom}`}></span>
              Vergroten
            </FancyboxButton>
          </Fancybox>
        </div>
      )}
    </React.Fragment>
  )
};

export default Preview


