import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetImagePosition, setField } from '../../actions/formActions';

import Button from '../../components/button';
import FileInput from '../../components/fileInput';
import SelectInput from '../../components/selectInput';
import TextInput from '../../components/textInput';
import TextareaInput from '../../components/textareaInput';
import Tooltip from '../../components/tooltip';
import RadioGroup from '../../components/radioGroup';

import { optionsSize, optionsLogoType } from './fieldOptions';

import globalStyle from '../../style.module.css';
import RangeInput from '../../components/rangeInput';

const Form = ({ handleDownload }) => {

  const { media_type, media_size, logo_type, name, function_name, quote, image_x, image_y, image_scale, picture } = useSelector(state => state.form)

  const dispatch = useDispatch();

  const handleChange = (field, payload) => {
    // rest image position on image change
    if (field === 'media_size' || (picture && field === 'picture')) {
      dispatch(resetImagePosition())
    }

    dispatch(setField(field, payload))
  }

  return (
    <form className={globalStyle.form} onSubmit={handleDownload}>
      <div className={globalStyle.formRow}>
        <div className={globalStyle.formLabel}>
          <label htmlFor="media_size">
            Waar wil je deze foto voor gebruiken?
          </label>
        </div>
        <div className={globalStyle.formInput}>
          <SelectInput
            identifier="media_size"
            currentMediaType={media_type}
            currentValue={media_size}
            options={optionsSize}
            handleChange={handleChange}
          />
        </div>
        <small>Download eerst je foto voordat je een ander formaat kiest</small>
      </div>


      {media_size === 'linkedin-post' ? (
        <div className={globalStyle.formRow}>
          <div className={globalStyle.formLabel}>
            <label htmlFor="quote">
              Jouw bijdrage
            </label>
          </div>
          <div className={globalStyle.formInput}>
            <TextareaInput
              identifier="quote"
              limit={165}
              handleChange={handleChange}
              value={quote}
            />
          </div>
          <Tooltip text="Wat is jouw bijdrage aan een gezond, fit en veerkrachtig Nederland?" />
        </div>
      ) : null}

      {media_size === 'linkedin-post' ? (
        <div className={globalStyle.formRow}>
          <div className={globalStyle.formLabel}>
            <label htmlFor="name">
              Wat is je naam?
            </label>
          </div>
          <div className={globalStyle.formInput}>
            <TextInput
              identifier="name"
              limit={35}
              handleChange={handleChange}
              value={name}
            />
          </div>
          <Tooltip text="Vul hier je volledige naam in." />
        </div>
      ) : null}

      {media_size === 'linkedin-post' ? (
        <div className={globalStyle.formRow}>
          <div className={globalStyle.formLabel}>
            <label htmlFor="function_name">
              Wat is je functie?
            </label>
          </div>
          <div className={globalStyle.formInput}>
            <TextInput
              identifier="function_name"
              limit={25}
              handleChange={handleChange}
              value={function_name}
            />
          </div>
          <Tooltip text="Vul hier je functienaam in." />
        </div>
      ) : null}

      <div className={globalStyle.formRow}>
        <div className={globalStyle.formLabel}>
          <label htmlFor="picture">
            Upload je foto
          </label>
        </div>
        <div className={globalStyle.formInput}>
          <FileInput
            identifier="picture"
            handleChange={handleChange}
            label={'Foto toevoegen'}
          />
        </div>
        <Tooltip text="Upload hier een foto van jezelf, het liefst met een egale achtergrond." />
      </div>

      {picture ? (
        <React.Fragment>
          <div className={globalStyle.formRow}>
            <div className={globalStyle.formLabel}>
              <label htmlFor="image_scale">
                Foto schaal
              </label>
            </div>
            <div className={globalStyle.formInput}>
              <RangeInput
                identifier="image_scale"
                min="50"
                max="200"
                steps="10"
                value={image_scale}
                handleChange={handleChange}
              />
            </div>
            <Tooltip text="Pas de schaal van de afbeelding aan." />
          </div>

          <div className={globalStyle.formRow}>
            <div className={globalStyle.formLabel}>
              <label htmlFor="image_x">
                Foto positie horizontaal
              </label>
            </div>
            <div className={globalStyle.formInput}>
              <RangeInput
                identifier="image_x"
                min="-100"
                max="100"
                steps="10"
                value={image_x}
                handleChange={handleChange}
              />
            </div>
            <Tooltip text="Pas de horizontaal positie van de afbeelding aan." />
          </div>

          <div className={globalStyle.formRow}>
            <div className={globalStyle.formLabel}>
              <label htmlFor="image_y">
                Foto positie verticaal
              </label>
            </div>
            <div className={globalStyle.formInput}>
              <RangeInput
                identifier="image_y"
                min="-100"
                max="100"
                steps="10"
                value={image_y}
                handleChange={handleChange}
              />
            </div>
            <Tooltip text="Pas de verticale positie van de afbeelding aan." />
          </div>
        </React.Fragment>
      ) : null}

      <fieldset className={globalStyle.formRow}>
        <div className={globalStyle.formLabel}>
          <legend>
            Logo variant
          </legend>
        </div>
        <div className={`${globalStyle.formInput} ${globalStyle.formInputInline}`}>
          <RadioGroup
            identifier="logo_type"
            currentValue={logo_type}
            options={optionsLogoType}
            handleChange={handleChange}
          />
        </div>
        <Tooltip text="Kies een logo variant die het beste past bij jouw foto." />
      </fieldset>

      <div className={globalStyle.formRow}>
        <div className={globalStyle.formInput}>
          <Button type="submit">
            <span className={`${globalStyle.icon} ${globalStyle.iconDownload}`}></span>
            Download
          </Button>
        </div>
      </div>

    </form>
  )
}

export default Form;
