import React, { useEffect } from "react";

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

function Fancybox({ delegate, options, children }) {
  const _delegate = delegate || "[data-fancybox]";

  useEffect(() => {
    const opts = options || {};

    NativeFancybox.bind(_delegate, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, [_delegate, options]);

  return <>{children}</>;
}

export default Fancybox;
