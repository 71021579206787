import style from './style.module.css';

import { trimNewLines } from '../../utilities';

const TextareaInput = ({ handleChange, value, identifier, limit = 0 }) => {

  const handleKeyUp = (event) => {
    if (limit > 0 && limit < event.currentTarget.value.length) {
      event.currentTarget.value = event.currentTarget.value.substring(0, limit);
      return;
    }

    handleChange(identifier, trimNewLines(event.currentTarget.value))
  };

  return (
    <div className={style.textareaInputGroup}>
      {limit > 0 && (
        <div className={style.characterCount}>
          <span>
            {value.length}
          </span>
          <span>{limit}</span>
        </div>
      )}
      <textarea
        id={identifier}
        className={`${style.textareaInput} ${limit > 0 ? style.textareaInputLimited : ''}`}
        rows="4"
        onKeyUp={handleKeyUp}
        defaultValue={value}
      />
    </div>
  );
}

export default TextareaInput;
