import Page from '../../components/page';

import globalStyle from '../../style.module.css'

const Accessibility = () => {
  const pageTitle = "Toegankelijkheid";
  const location = window.location.origin;
  return (
    <Page title={pageTitle}>
      <div className={globalStyle.mainWrapper}>
        <h1 className={globalStyle.assistive}>{pageTitle}</h1>
        <div className={`${globalStyle.content} ${globalStyle.contentArticle}`}>
          <div className={globalStyle.intro}>
            <p>samenbeterdoejemee.nl wil dat iedereen deze website kan gebruiken. Kom je toch een pagina tegen die niet toegankelijk is? Dan kun je dit aan ons melden.</p>
          </div>
          <h2>Wat is een toegankelijke website?</h2>
          <p>Een toegankelijke website is voor alle doelgroepen beter te gebruiken. Daarom is in het <a href='https://wetten.overheid.nl/BWBR0040936/2018-07-01'>Tijdelijk besluit digitale toegankelijkheid overheid</a> bepaald dat overheidssites moeten voldoen aan toegankelijkheidseisen zoals vastgelegd in EN 301 549/WCAG 2.1 en daarover verantwoordelijkheid moeten afleggen in een <a href="https://www.toegankelijkheidsverklaring.nl/register/8124" rel='noreferrer' target={'_blank'}>gepubliceerde toegankelijkheidsverklaring</a>.</p>
          <p>Wij borgen een goede toegankelijkheid van onze website door diverse maatregelen binnen onze (dagelijkse) processen.</p>
          <p>In februari 2023 vond een toegankelijkheidsonderzoek voor samenbeterdoejemee.nl plaats. Dit is het <a rel='noreferrer' target={'_blank'} href={`${location}/media/uploads/onderzoeksrapport-samenbeter-toegankelijkheid-juli2023.pdf`}>onderzoeksrapport</a>. </p>
          <h2>Garanderen toegankelijkheid</h2>
          <p>Wij garanderen een goede toegankelijkheid door diverse maatregelen binnen onze (dagelijkse) processen:</p>
          <ul>
            <li>Toegankelijkheid ‘by design’: toegankelijkheid is vanaf de start onderdeel van alle stappen in het ontwerp-, bouw en redactionele proces van onze website.</li>
            <li>Onderzoek: onafhankelijke deskundigen toetsen regelmatig (onderdelen van) de website op toegankelijkheid. Zowel voor de functioneel-technische onderdelen als de redactionele aspecten. Gevonden knelpunten lossen wij duurzaam op.</li>
            <li>Kennis medewerkers: onze medewerkers houden hun kennis over toegankelijkheid op peil en passen dit toe waar nodig.</li>
          </ul>
          <h2>Problemen met toegankelijkheid melden</h2>
          <p>Heb je vragen of opmerkingen? Of wil je een pagina gebruiken die niet toegankelijk is? Stuur dan een mail naar <a href="mailto:werkenbijvws@minvws.nl" className={globalStyle.external} aria-label="Mail naar werkenbijvws@minvws.nl (opent externe mailprogramma)">werkenbijvws@minvws.nl.</a>.</p>
        </div>
      </div>
    </Page>
  )
}

export default Accessibility;
