import React from 'react'
import style from './style.module.css'

const FancyboxButton = ({ children, type = null, src = null }) => {
  return (
    <button
      className={`${style.button}`}
      data-fancybox={type}
      data-src={src}
    >{children}</button>
  )
}

export default FancyboxButton
