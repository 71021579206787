import style from './style.module.css';

const RangeInput = ({ handleChange, value, identifier, min, max, steps }) => {

  const handleRange = (event) => {
    handleChange(identifier, event.currentTarget.value)
  };

  return (
    <input
      className={style.range}
      type="range"
      id={identifier}
      name={identifier}
      min={min}
      max={max}
      steps={steps}
      value={value}
      onChange={handleRange}
    />
  );
}

export default RangeInput;
