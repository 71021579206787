export const optionsMediaType = [
    {
        key: 'linkedin',
        label: 'LinkedIn',
        value: 'linkedin',
    }
]


export const optionsSize = [
    {
        key: 'linkedin',
        options: [
            {
                label: 'Social media post',
                value: 'linkedin-post',
            },
            {
                label: 'Banner',
                value: 'linkedin-banner',
            },
            {
                label: 'Profielfoto',
                value: 'linkedin-profile-picture',
            }
        ]
    },
]

export const optionsLogoType = [
  {
      key: 'transparent',
      label: 'transparant',
      value: 'transparent',
  }, {
      key: 'not_transparent',
      label: 'niet transparant',
      value: 'not_transparent',
  }
]
