import React, { useState } from 'react';

import globalStyle from '../../style.module.css'
import style from './style.module.css';

const Tooltip = ({ text }) => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <React.Fragment>
      <button
        aria-label={text}
        className={`${style.tooltip} ${active ? style.tooltipActive : ''}`}
        onClick={handleClick}
        type="button"
      >
        <div className={style.tooltipIcons}>
          <span className={`${globalStyle.icon} ${globalStyle.iconQuestion} ${style.tooltipIcon}`}></span>
          <span className={`${globalStyle.icon} ${globalStyle.iconCross} ${style.tooltipIcon}`}></span>
        </div>
      </button>
      {active && (
        <div className={style.tooltipText}>{text}</div>
      )}
    </React.Fragment>
  );

}

export default Tooltip;
