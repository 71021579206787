import * as types from '../actions/actionTypes';

const initialState = {
  font_scale: 1,
  name: 'Naam Achternaam',
  function_name: 'Titel functie',
  logo_type: 'transparent',
  picture: null,
  media_type: 'linkedin',
  media_size: 'linkedin-post',
  quote: 'Ik zorg dat mensen goed weten wat ze kunnen verwachten van de vaccinatie. Zo verlaag ik de drempel om naar een priklocatie te komen.',
  image_x: 0,
  image_y: 0,
  image_scale: 100,
};

export default function Form(state = initialState, action) {
  switch (action.type) {
    case types.SET_FIELD:
      return {
        ...state,
        [action.field]: action.payload,
      }
    case types.SET_IMAGE_POSITION:
      return {
        ...state,
        image_x: action.position.x ?? 0,
        image_y: action.position.y ?? 0,
      }
    case types.RESET_IMAGE_POSITION:
      return {
        ...state,
        image_x: 0,
        image_y: 0,
        image_scale: 100,
      }
    default:
      return state;
  }
}
