import Breadcrumb from '../breadcrumb';

import style from './style.module.css';

const Navbar = ({ title }) => {
  return (
    <div className={style.navbar}>
      <div className={style.navbarWrapper}>
        <Breadcrumb title={title} />
      </div>
    </div>
  )
}

export default Navbar;
