import React from 'react';

import style from './style.module.css';

const Loader = () => {
  return (
    <div className={style.loadingView}>
      <svg viewBox="25 25 50 50" className={style.loaderIcon}>
        <circle cx="50" cy="50" r="20"></circle>
      </svg>
      <span>Een moment geduld graag.</span>
      <span>Uw materiaal wordt gegenereerd.</span>
    </div>
  );
};

export default Loader;
