import React from 'react'
import style from './style.module.css'

const Button = ({ children, disabled = false, onClick, title, classes, type = 'button' }) => {
  return (
    <button
      disabled={disabled}
      className={`${style.button} ${classes}`}
      onClick={onClick}
      title={title}
      type={type}
    >{children}</button>
  )
}

export default Button
