import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setImagePosition } from '../../actions/formActions';

import CampagneLogo from './assets/campagne-logo.png';
import CampagneLogoSolid from './assets/campagne-logo-solid.png';

import style from './style.module.css'

const LinkedInBanner = ({ editor }) => {

  const { media_size, font_scale, logo_type, picture, image_x, image_y, image_scale } = useSelector(state => state.form)

  const dispatch = useDispatch()
  const ref = useRef()

  const [pressed, setPressed] = useState(false)
  const [position, setPosition] = useState({ x: image_x, y: image_y })

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${image_x}%, ${image_y}%) scale(${image_scale}%)`
    }
  }, [image_x, image_y, image_scale])

  const onMouseMove = (event) => {
    if (editor && picture && pressed) {
      setPosition({
        x: position.x + event.movementX / (ref.current.clientWidth / 100) * 0.5,
        y: position.y + event.movementY / (ref.current.clientHeight / 100) *  0.5,
      })
      dispatch(setImagePosition(position))
    }
  }

  const isTransparentLogo = logo_type === 'transparent';

  return (
    <div
      className={`${style.poster} size-${media_size} ${picture ? style.hasPicture : ''} ${pressed ? style.isGrabbing : ''}`}
      style={{ "--fontScale": font_scale }}
      onMouseMove={onMouseMove}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}>
      <div className={style.link}>
        Doe je mee? <br />
        Lees meer op <strong>werkenbijvws.nl</strong>
      </div>
      <div className={style.gradient}></div>
      <div className={style.pixelGrid}></div>
      <div className={style.pictureWrapper}>
        <img ref={ref} className={`${style.picture}`} src={`${picture ?? '/media/images/placeholders/banner-preview.jpg'}`} alt="Foto element aan de rechterzijde van de poster (te uploaden via 'upload jouw foto in het invoerveld')" />
      </div>
      {isTransparentLogo ? (
        <img className={style.campagneLogo} src={CampagneLogo} alt="Logo Samen beter. Doe je mee?" />
      ) : (
        <img className={style.campagneLogo} src={CampagneLogoSolid} alt="Logo Samen beter. Doe je mee?" />
      )}
    </div>
  )
}

export default LinkedInBanner
