import { Link } from 'react-router-dom';
import style from './style.module.css';

const Footer = () => {
  return (
    <footer className={style.siteFooter}>
      <div className={style.siteFooterWrapper}>
        <div className={style.siteFooterColumn}>
          <h2>Service</h2>
          <ul>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/sitemap">Sitemap</Link>
            </li>
          </ul>
        </div>
        <div className={style.siteFooterColumn}>
          <h2>Over deze site</h2>
          <ul>
            <li>
              <Link to="/over">Over</Link>
            </li>
            <li>
              <Link to="/copyright">Copyright</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
              <Link to="/cookies">Cookies</Link>
            </li>
            <li>
              <Link to="/toegankelijkheid">Toegankelijkheid</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
