import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setImagePosition } from '../../actions/formActions';

import campagneLogo from './assets/campagne-logo.png';

import style from './style.module.css'

const LinkedInProfilePicture = ({ preview, editor }) => {
  const { media_size, font_scale, logo_type, picture, image_x, image_y, image_scale } = useSelector(state => state.form)

  const dispatch = useDispatch()
  const ref = useRef()

  const [pressed, setPressed] = useState(false)
  const [position, setPosition] = useState({ x: image_x, y: image_y })

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${image_x}%, ${image_y}%) scale(${image_scale}%)`
    }
  }, [image_x, image_y, image_scale])

  const onMouseMove = (event) => {
    if (editor && picture && pressed) {
      setPosition({
        x: position.x + event.movementX / (ref.current.clientWidth / 100) * 0.5,
        y: position.y + event.movementY / (ref.current.clientHeight / 100) *  0.5,
      })
      dispatch(setImagePosition(position))
    }
  }

  const isTransparentLogo = logo_type === 'transparent';

  return (
    <div
      className={`${style.poster} size-${media_size} ${picture ? style.hasPicture : ''} ${pressed ? style.isGrabbing : ''} ${preview ? style.rounded : ''}`}
      style={{ "--fontScale": font_scale }}
      onMouseMove={onMouseMove}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
    >
      <img className={style.campagneLogo} src={campagneLogo} alt="Logo Samen beter. Doe je mee?" />
      {!isTransparentLogo && (
        <div className={style.gradient}></div>
      )}
      <div className={`${style.pictureWrapper}`}>
        <img ref={ref} className={`${style.picture}`} src={`${picture ?? '/media/images/placeholders/profile-picture-preview.png'}`} alt="Foto element vullend in de poster (te uploaden via 'upload jouw foto in het invoerveld')" />
      </div>
    </div>
  )
}

export default LinkedInProfilePicture
