import * as types from './loginTypes';

export function setLogin(payload) {
  return {
    type: types.SET_LOGIN,
    payload
  }
}


