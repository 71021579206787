import * as types from '../actions/loginTypes';

const initialState = {
    isLoggedIn: false,
};

export default function Form(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOGIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      }
    default:
      return state;
  }
}
