import { Link } from 'react-router-dom';
import Page from '../../components/page';

import globalStyle from '../../style.module.css'

const NotFound = () => {
  const pageTitle = "Niet gevonden";

  return (
    <Page title={pageTitle}>
      <div className={globalStyle.mainWrapper}>
        <h1 className={globalStyle.assistive}>{pageTitle}</h1>
        <div className={`${globalStyle.content} ${globalStyle.contentArticle}`}>
          <div className={globalStyle.intro}>
            <p>De pagina die je wilde zien of het bestand dat je wilde bekijken is niet gevonden.</p>
          </div>
          <h3>Je kunt de informatie die je zoekt mogelijk vinden via de volgende pagina's:</h3>
          <ul>
            <li>
              <Link to="/">Homepage</Link>
            </li>
            <li>
              <Link to="/sitemap">Sitemap</Link>
            </li>
          </ul>
          <p>
            <code>Foutcode: 404</code>
          </p>
        </div>
      </div>
    </Page>
  );
}

export default NotFound;
