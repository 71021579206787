import Page from '../../components/page';

import globalStyle from '../../style.module.css'

const About = () => {
  const pageTitle = "Over";

  return (
    <Page title={pageTitle}>
      <div className={globalStyle.mainWrapper}>
        <h1 className={globalStyle.assistive}>{pageTitle}</h1>
        <div className={`${globalStyle.content} ${globalStyle.contentArticle}`}>
          <div className={globalStyle.intro}>
            <p>Dit fotokader is een initiatief van het ministerie van Volksgezondheid, Welzijn en Sport (VWS).</p>
          </div>
          <p>Op deze website is er de mogelijkheid om eigen beeldmateriaal te maken. Dit kun je zelf plaatsen op bijvoorbeeld LinkedIn om te laten zien dat je trots bent op het werken bij VWS.</p>
          <p>Meer informatie vind je op vws#Net in de groep <a href="https://www.vwsnet.nl/Project/VacaturesvanVWS" target="_blank" rel="noopener noreferrer" className={globalStyle.external} aria-label="www.vwsnet.nl (opent externe website)">Werken bij VWS.</a></p>
        </div>
      </div>
    </Page>
  );
}

export default About;
