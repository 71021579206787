import Page from '../../components/page';

import globalStyle from '../../style.module.css'

const Privacy = () => {
  const pageTitle = "Privacy";

  return (
    <Page title={pageTitle}>
      <div className={globalStyle.mainWrapper}>
        <h1 className={globalStyle.assistive}>{pageTitle}</h1>
        <div className={`${globalStyle.content} ${globalStyle.contentArticle}`}>
          <div className={globalStyle.intro}>
            <p>Bewaren persoonsgegevens</p>
          </div>
          <p>Deze website bewaart geen persoonsgegevens. Alle gegenereerde posters of social-uitingen worden nergens opgeslagen.</p>
        </div>
      </div>
    </Page>
  );
}

export default Privacy;
