import style from './style.module.css'

const RadioGroup = ({ identifier, currentValue, options, handleChange }) => {

  const onChange = (event) => {
    let value = event.target.value

    // Force to boolean if its a string containing a boolean
    if (event.target.value === 'true' || event.target.value === 'false') {
      value = (event.target.value === 'true')
    }

    handleChange(identifier, value)
  }

  return (
    options.map(option => {
      const key = `${identifier}-${option.key}`
      const isChecked = currentValue === option.value

      return (
        <label
          key={key}
          htmlFor={key}
          className={style.radioInputWrapper}
          style={option?.color ? { '--theme': option.color } : {}}
        >
          <input
            id={key}
            name={identifier}
            type="radio"
            onChange={onChange}
            value={option.value}
            checked={isChecked}
            className={style.radioInput}
          />
          {option.label}
        </label>
      )

    })
  )
}

export default RadioGroup
