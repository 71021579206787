import html2canvas from 'html2canvas'

export async function processDownload(node, type) {
  return new Promise(async (resolve, reject) => {
    await html2canvas(node, { scale: 1 }).then((canvas) => {
      const data = canvas.toDataURL('image/png');
      downloadFile(data, `${type}-samen-beter.png`);
      resolve();
    });
  });
}

export function downloadFile(base64, filename = '') {
  var block = base64.split(';');
  var contentType = block[0].split(':')[1];
  var realData = block[1].split(',')[1];
  var blob = b64toBlob(realData, contentType);
  forceDownload(blob, filename);
}

export function forceDownload(data, name = 'file') {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  anchor.style = 'display: none';

  anchor.href = window.URL.createObjectURL(data);
  anchor.download = name;
  anchor.click();
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {
    type: contentType,
  });
  return blob;
}


export const debounce = (callback, wait, immediate = false) => {
  let timeout = null

  return function () {
    const callNow = immediate && !timeout
    const next = () => callback.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(next, wait)

    if (callNow) {
      next()
    }
  }
}

export function trimNewLines(input) {
  return input.replace(/[\r\n]+/g, ' ');
}

export async function getAuthToken(credentials) {
  return fetch('/auth.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(response => response.json())
    .then((response) => {
      return response.token;
    })
    .catch(() => {
      return null;
    });
}

export async function validateAuthToken(token) {
  return new Promise((resolve, reject) => {
    fetch('/auth.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token
      })
    })
      .then(response => response.json())
      .then((response) => {
        resolve(response.success);
      })
      .catch(() => {
        resolve(false);
      });
  });
}