import Copyright from './containers/copyright';
import Generator from './containers/generator';
import Privacy from './containers/privacy';
import Cookies from './containers/cookies';
import Accessibility from './containers/accessibility';
import Contact from './containers/contact';
import Sitemap from './containers/sitemap';
import About from './containers/about';
import NotFound from './containers/notFound';

const routes = [
  {
    path: "/",
    element: <Generator />
  },
  {
    path: "/copyright",
    element: <Copyright />
  },
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/cookies",
    element: <Cookies />
  },
  {
    path: "/toegankelijkheid",
    element: <Accessibility />
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/over",
    element: <About />
  },
  {
    path: "/sitemap",
    element: <Sitemap />
  },
  {
    path: "*",
    element: <NotFound />
  }
]

export default routes
