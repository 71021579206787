import style from './style.module.css'

const SelectInput = ({ identifier, currentValue, currentMediaType, options, handleChange }) => {

  const onChange = (event) => {
    handleChange(identifier, event.currentTarget.value)
  }

  const selectableOptions = options.filter(r => r.key === currentMediaType)[0]

  return (
    <div className={style.selectInputWrapper}>
      <select
        id={identifier}
        className={style.selectInput}
        defaultValue={currentValue}
        onChange={onChange}
      >
        {
          selectableOptions.options.map(option => {
            return (
              <option key={`select-${option.value}`} value={option.value}>{option.label}</option>
            )
          })
        }
      </select>
    </div>
  );

}

export default SelectInput;
