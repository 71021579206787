import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';

import { processDownload } from '../../utilities';

import Page from '../../components/page'
import Preview from '../preview'
import Form from '../form'
import Loader from '../../components/loader';

import { optionsSize } from '../form/fieldOptions'

import globalStyle from '../../style.module.css'
import style from './style.module.css'

const Generator = () => {
  const { media_size, media_type } = useSelector(state => state.form)

  const selectedType = optionsSize.filter(r => r.key === media_type)[0]
  const selectedSize = selectedType?.options.find(option => option.value === media_size)

  const mediaRender = useRef(null);

  const [loading, setLoading] = useState(false);

  const handleDownload = (e) => {
    e.preventDefault();
    
    setLoading(true);

    processDownload(mediaRender.current, media_size).then(() => {
      setLoading(false);

      window.trackGenerate(selectedSize.label ?? media_size);
    })
  }

  return (
    <Page>

      {loading && <Loader />}

      <div className={style.wrapper}>
        <div className={style.container}>
          <div className={style.row}>
            <div className={style.formWrapper}>
                <div className={style.formIntro}>
                <h1>Samen beter. Doe je mee?</h1>
                <p>Word ambassadeur van VWS: maak jouw socialmedia-uiting.</p>
                <p>Creëer je eigen profielfoto, header of social media post!</p>
                <p>Vertel over waar jij aan bijdraagt in jouw werk, wie je bent of wat je doet door een quote toe te voegen aan jouw social media post.</p>
                <p>Je vult je quote, naam en functie in. Samen met je foto download je een social media post. Als je kiest voor een banner of profielfoto, dan upload je alleen een foto.</p>
                <p>De foto kun je gebruiken als profielfoto of header afbeelding op LinkedIn, vws#Net of andere websites.</p>
                <p>Meer informatie vind je op vws#Net in de groep <a href="https://www.vwsnet.nl/Project/VacaturesvanVWS" target="_blank" rel="noopener noreferrer" className={globalStyle.external} aria-label="www.vwsnet.nl (opent externe website)">Werken bij VWS.</a></p>
              </div>
              <Form handleDownload={handleDownload} />
            </div>
            <div className={style.preview}>
              <Preview info preview editor />
            </div>
            <div className={`preview-render`}>
              <div ref={mediaRender}>
                <Preview />
              </div>
            </div>
            {media_size === 'linkedin-banner' && (
              <div id="dialog-content" className={style.dialog}>
                <Preview preview />
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Generator
