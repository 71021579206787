import Page from '../../components/page';

import globalStyle from '../../style.module.css'

const Contact = () => {
  const pageTitle = "Contact";

  return (
    <Page title={pageTitle}>
      <div className={globalStyle.mainWrapper}>
        <h1 className={globalStyle.assistive}>{pageTitle}</h1>
        <div className={`${globalStyle.content} ${globalStyle.contentArticle}`}>
          <div className={globalStyle.intro}>
            <p>Vragen over deze website?</p>
          </div>
          <p>Neem dan contact op via <a href="mailto:werkenbijvws@minvws.nl" className={globalStyle.external} aria-label="Mail naar werkenbijvws@minvws.nl (opent externe mailprogramma)">werkenbijvws@minvws.nl</a>, dit is het e-mailadres van ons VWS Talent Center.</p>
        </div>
      </div>
    </Page>
  );
}

export default Contact;
