import Page from '../../components/page';

import globalStyle from '../../style.module.css'

const Copyright = () => {
  const pageTitle = "Copyright";

  return (
    <Page title={pageTitle}>
      <div className={globalStyle.mainWrapper}>
        <h1 className={globalStyle.assistive}>{pageTitle}</h1>
        <div className={`${globalStyle.content} ${globalStyle.contentArticle}`}>
          <div className={globalStyle.intro}>
            <p>Tenzij anders vermeld is op de inhoud van deze website de Creative Commons zero verklaring (CC0) van toepassing. Dit houdt in dat hergebruik van de inhoud van deze site is toegestaan, tenzij bij een bepaald onderdeel (bijvoorbeeld een document) staat aangegeven dat op dat onderdeel een auteursrechtelijke beperking van toepassing is.</p>
          </div>
          <h2>Uitzondering voor beeld</h2>
          <p>Op foto's, video's, infographics en alle andere vormen van beeld is de CC0-verklaring niet van toepassing. Het is dus niet toegestaan om beeld te hergebruiken of over te dragen, tenzij bij het beeld expliciet is aangegeven dat dat wél is toegestaan. Bij hergebruik van beeld is het overnemen van de naam van de maker – indien vermeld – verplicht. Daarnaast geldt dat bij het citeren van de inhoud niet de indruk gewekt mag worden dat de Rijksoverheid zonder meer de strekking van het afgeleide werk onderschrijft.</p>
        </div>
      </div>
    </Page>
  );
}

export default Copyright;
