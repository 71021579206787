import React, { useState } from 'react';

import globalStyle from '../../style.module.css'

import Logo from './logo-ro.svg';
import LogoMobile from './logo-ro-mobile.svg';

import style from './style.module.css';
import TextInput from '../../components/textInput';
import Button from '../../components/button';
import { getAuthToken } from '../../utilities';

const Login = ({ setToken }) => {
  const pageTitle = "Inloggen Fotokader - Samen beter. Doe je mee?";

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});

  const handleChange = (field, payload) => {
    if (field === 'username') {
      setUserName(payload)
    }
    if (field === 'password') {
      setPassword(payload)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const _error = {};

    if (!username) {
      _error.username = 'Vul hier een gebruikersnaam in.';
    }

    if (!password) {
      _error.password = 'Vul hier een inlogcode in.';
    }

    if (!username || !password) {
      setError(_error);
      return;
    };

    const token = await getAuthToken({
      username,
      'login_code': password
    });
    
    if (token) {
      localStorage.setItem('token', token);
      setToken(token);
    } else {
      setUserName('');
      setPassword('');
      setError({ login: 'U heeft geen geldige gebruikersnaam of inlogcode ingevoerd. Probeer het nogmaals. Lukt het inloggen niet neem dan contact op met de beheerder' });
    }
  }

  return (
    <React.Fragment>

      <header>
        <a href="/">
          <div className={style.logo}>
            <div className={style.logoWrapper}>
              <picture>
                <source srcSet={Logo} media="(min-width: 768px)" />
                <img
                  src={LogoMobile}
                  alt={`Logo Rijksoverheid - Naar de homepage van samenbeterdoejemee.nl`}
                />
              </picture>
            </div>
          </div>
        </a>
      </header>

      <main id="content-wrapper" tabIndex="-1">
        <div className={globalStyle.mainWrapper}>
          <h1 className={globalStyle.assistive}>{pageTitle}</h1>
          <div className={`${globalStyle.content} ${globalStyle.contentArticle}`}>
            <h1>{pageTitle}</h1>

            {error.login && <p className={style.error}>{error.login}</p>}

            <p className={style.meta}>* verplichte velden</p>

            <form className={`${globalStyle.form} ${style.form}`}>

              <div className={globalStyle.formRow}>
                <div className={globalStyle.formLabel}>
                  <label htmlFor="username">
                    Gebruikersnaam*
                  </label>
                </div>
                <div className={globalStyle.formInput}>
                  <TextInput
                    identifier="username"
                    handleChange={handleChange}
                    value={username}
                  />
                  {error.username && <p className={style.error}>{error.username}</p>}
                </div>
              </div>

              <div className={globalStyle.formRow}>
                <div className={globalStyle.formLabel}>
                  <label htmlFor="password">
                    Inlogcode*
                  </label>
                </div>
                <div className={globalStyle.formInput}>
                  <TextInput
                    identifier="password"
                    type='password'
                    handleChange={handleChange}
                    value={password}
                  />
                  {error.password && <p className={style.error}>{error.password}</p>}
                </div>
              </div>

              <div className={globalStyle.formRow}>
                <div className={globalStyle.formInput}>
                  <Button
                    onClick={handleSubmit}
                  >
                    Log in
                  </Button>
                </div>
              </div>

            </form>

            <p>Meer informatie over inloggen vind je op <a href='https://www.vwsnet.nl/umbraco/vws-breed/werken-bij-vws-en-vacatures/fotokader-samen-beter-doe-je-mee/'>vws#Net</a> of stuur een mail naar <a href='mailto:werkenbijvws@minvws.nl.'>werkenbijvws@minvws.nl</a> </p>

          </div>
        </div>
      
      </main>
    </React.Fragment>
  );
}

export default Login;
