import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../navbar';

import Logo from './logo-ro.svg';
import LogoMobile from './logo-ro-mobile.svg';

import style from './style.module.css';

const Header = ({ title }) => {
  return (
    <>
      <header>
        <div className={style.skiplinks}>
          <a href="#content-wrapper">Ga direct naar inhoud</a>
        </div>
        <Link to="/">
          <div className={style.logo}>
            <div className={style.logoWrapper}>
              <picture>
                <source srcSet={Logo} media="(min-width: 768px)" />
                <img
                  src={LogoMobile}
                  alt={`Logo Rijksoverheid - Naar de homepage van samenbeterdoejemee.nl`}
                />
              </picture>
            </div>
          </div>
        </Link>
      </header>
      <Navbar title={title} />
    </>
  );
}

export default Header;
