import React, { useRef, useState } from 'react'

import Button from '../button';

import style from './style.module.css'

const FileInput = ({ identifier, handleChange, label = 'Bestand toevoegen' }) => {

  const fileInput = useRef(null);

  const [fileName, setFileName] = useState();

  const onChange = () => {
    const reader = new FileReader();
    const file = fileInput.current.files[0];

    if (!file) return;

    reader.readAsDataURL(file);
    reader.onload = (event) => {
      handleChange(identifier, event.target.result)
      setFileName(file.name)
    }
  }

  const onClick = () => {
    fileInput.current.click();
  };

  return (
    <div className={style.fileInputGroup}>
      <input
        id={identifier}
        className={style.fileInput}
        type="file"
        onChange={onChange}
        ref={fileInput}
        accept="image/*"
      />
      <label htmlFor={identifier}>
        <Button classes={style.fileInputButton} onClick={onClick}>
          {label}
        </Button>
        <div className={style.fileResult}>
          <span className={style.fileResultText}>{fileName ? fileName : 'Selecteer een bestand...'}</span>
        </div>
      </label>
    </div>
  );
}

export default FileInput;
