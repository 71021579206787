import { Link } from 'react-router-dom';

import Page from '../../components/page';

import globalStyle from '../../style.module.css'

const Sitemap = () => {
  const pageTitle = "Sitemap";

  return (
    <Page title={pageTitle}>
      <div className={globalStyle.mainWrapper}>
        <h1 className={globalStyle.assistive}>{pageTitle}</h1>
        <div className={`${globalStyle.content} ${globalStyle.contentArticle}`}>
          <div className={globalStyle.intro}>
            <p>Sitemap</p>
          </div>
          <ul>
          <li>
              <Link to="/over">Over</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/copyright">Copyright</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
              <Link to="/cookies">Cookies</Link>
            </li>
            <li>
              <Link to="/toegankelijkheid">Toegankelijkheid</Link>
            </li>
          </ul>
        </div>
      </div>
    </Page>
  )
}

export default Sitemap;

