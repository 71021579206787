import { Link } from 'react-router-dom';

import globalStyle from '../../style.module.css'
import style from './style.module.css'

function CurrentPage({ title }) {
  return title ? <span aria-current="page">{title}</span> : null;
}

const Breadcrumb = ({ title }) => {
  return (
    <nav className={style.breadcrumb} aria-labelledby="breadCrumbNavLabel">
      <span className={globalStyle.assistive} id="breadCrumbNavLabel">U bevindt zich hier:</span>
      <Link to="/">Fotokader - Samen beter. Doe je mee?</Link>
      <CurrentPage title={title} />
    </nav>

  )
}

export default Breadcrumb;
