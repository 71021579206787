import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setImagePosition } from '../../actions/formActions';

import CampagneLogo from './assets/campagne-logo.png';
import CampagneLogoSolid from './assets/campagne-logo-solid.png';
import RoLogo from './assets/ro-logo.png';

import style from './style.module.css'

const LinkedInPost = ({ editor }) => {
  const { name, function_name, media_size, font_scale, logo_type, quote, picture, image_x, image_y, image_scale } = useSelector(state => state.form)

  const dispatch = useDispatch()
  const ref = useRef()

  const [pressed, setPressed] = useState(false)
  const [position, setPosition] = useState({ x: image_x, y: image_y })

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${image_x}%, ${image_y}%) scale(${image_scale}%)`
    }
  }, [image_x, image_y, image_scale])

  const onMouseMove = (event) => {
    if (editor && picture && pressed) {
      setPosition({
        x: position.x + event.movementX / (ref.current.clientWidth / 100) * 0.5,
        y: position.y + event.movementY / (ref.current.clientHeight / 100) *  0.5,
      })
      dispatch(setImagePosition(position))
    }
  }

  const isTransparentLogo = logo_type === 'transparent';

  return (
    <React.Fragment>
      <div
        className={`${style.poster} size-${media_size} ${picture ? style.hasPicture : ''} ${pressed ? style.isGrabbing : ''}`}
        style={{ "--fontScale": font_scale }}
        onMouseMove={onMouseMove}
        onMouseDown={() => setPressed(true)}
        onMouseUp={() => setPressed(false)}>
        <div className={style.content} aria-label="Inhoud element aan de linkerzeide van de poster (quote aan te passen via 'Jouw bijdrage' in het invoerveld, naam aan te passen via 'Wat is je naam?' in het invoerveld en functie aan te passen via 'Wat is je functie?' in het invoerveld).">
          <div className={style.contentInner}>
            {quote.length ? (
              <div className={`${style.quote} ${style.breakWord}`} dangerouslySetInnerHTML={{ __html: quote }}></div>
            ) : (
              ''
            )}
            <div className={style.info}>
              <div className={style.name}>{name}</div>
              <div className={style.function}>{function_name}</div>
            </div>
          </div>
          <div className={style.link}>
            Doe je mee? <br />
            Lees meer op <strong>werkenbijvws.nl</strong>
          </div>
        </div>
        <div className={style.pixelGrid}></div>
        <div className={style.pictureWrapper}>
          <img ref={ref} className={`${style.picture}`} src={`${picture ?? '/media/images/placeholders/post-preview.jpg'}`} alt="Foto element aan de rechterzijde van de poster (te uploaden via 'upload je foto' in het invoerveld')." />
        </div>
        {isTransparentLogo ? (
          <img className={style.campagneLogo} src={CampagneLogo} alt="Logo Samen beter. Doe je mee?" />
        ) : (
          <img className={style.campagneLogo} src={CampagneLogoSolid} alt="Logo Samen beter. Doe je mee?" />
        )}
        <img className={style.roLogo} src={RoLogo} alt="Logo Rijksoverheid" />
      </div>
    </React.Fragment>
  )
}

export default LinkedInPost
