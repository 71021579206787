import React, { useEffect, useState, useLayoutEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import routeConfig from './routes';
import Login from './containers/login';
import { validateAuthToken } from './utilities';

// base styling
import './typography.css';
import './generic.css';
import globalStyle from './style.module.css';
import Footer from './components/footer';

import { useDispatch, useSelector } from 'react-redux'
import { setLogin } from './actions/loginActions';

async function validateToken() {
  return await validateAuthToken(localStorage.getItem('token'));
}


const Wrapper = ({children}) => {
  const dispatch = useDispatch()
  
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
    if (window.location.pathname !== '/') {
      dispatch(setLogin(true))
    } else {
      dispatch(setLogin(false))
    }
  }, [location.pathname, dispatch]);
  return children
} 

export default function App() {
  const [token, setToken] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector(state => state.login)

  useEffect(() => {
    validateToken().then(token => {
      if (token) {
        setToken(localStorage.getItem('token'));
        dispatch(setLogin(true))

      } else {
        setShowLogin(true);
      }
    });
    window.trackPageLoad();
  }, [token, dispatch]);
  
 


  // useEffect(() => {
  //   if (window.location.pathname === '/toegankelijkheid') {
  //     setShowLogin(false);
  //   }
  // }, []);


  // if (!token && showLogin) {
  //   return (
  //     <BrowserRouter>
  //         <Footer/>
  //         <Routes>
  //           {
  //             routeConfig.map((r, index) => <Route key={`route-${index}`} path={r.path} element={r.element} />)
  //           }
  //         </Routes>
  //     </BrowserRouter>
  //   )
  // } else if (!token) {
  //   // return null;
  // }
  // loop thourgh routes and filter them to not contain the home route
  return (
    <div className={globalStyle.app}>
      <BrowserRouter>
        <Wrapper >
          
          {
            ( showLogin && !isLoggedIn  ) ? (
              <Login setToken={setToken}/>
            ) : (
              <Routes>
                {
                  routeConfig.map((r, index) => <Route key={`route-${index}`} path={r.path} element={r.element} />)
                }
              </Routes>
            )
          }
          <Footer/>
        </Wrapper>
      </BrowserRouter>

    </div>
  )
}
