import React from 'react';
import Header from '../../components/header';
const Page = ({ title, children }) => {
  return (
    <React.Fragment>
      <Header title={title} />
      <main id="content-wrapper" tabIndex="-1">
        {children}
      </main>
    </React.Fragment>
  )
}
export default Page;
